import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme (

{
    palette: {

        primary: {
            light: "rgba(165, 226, 246, 1)",
            main: "rgba(56, 183, 225, 1)",
            dark: "rgba(4, 111, 147, 1)",
        },
        secondary: {
            light: "rgba(224, 166, 247, 1)",
            main: "rgba(180, 58, 228, 1)",
            dark: "rgba(113, 5, 156, 1)",
        },
    }
}

);



export default Theme;
//React
import React from 'react';
import ReactDOM from 'react-dom';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import useStyles from "./Style"

class PupilComponent extends React.Component {
	
	constructor(props){
		super(props);
		 
	}
	
	render(){
		const {classes} = this.props
		
		const pupilName = this.props.pupilData["Pupil_Name__c"]; 
		
		const pupilEffort = this.props.pupilData["Effort_1_6__c"]; 
		
		const pupilGrade = this.props.pupilData["What_grade_did_pupil_achieve__c"];
		
		return (
			
			<Container maxWidth = "sd">
			<Paper className = {classes.paper}>

				<Grid item container spacing = {1}  justify="center">
				
					<Grid item container justify="center" xs={12}>
						<Grid item>
							<Typography component="h1" variant="subtitle1"  align = 'center' className = {classes.title}>
								{pupilName}
							</Typography>					
						</Grid>
					</Grid>				

					<Grid item container direction="row"  md = {2} xs={12}>

						<Grid item xs = {12}>
							<Typography component="h1" variant="subtitle1"  align = 'center'className = {classes.infoText} >
								Effort <br/> (1-6)
							</Typography>					
						</Grid>
						
						<Grid item xs = {12} align = 'center' className = {classes.gridItem}>
							<Autocomplete
							  id="effort-combo"
							  options={["1","2","3","4","5","6"]}
							  style={{ width: 150 }}
							  renderInput={(params) => <TextField error={false} {...params} label="Score" variant="outlined" />}	
							  value={ pupilEffort ? "" + pupilEffort : null  } 
							  onChange = { (event,value)=> {this.props.handleUpdatePupilData(pupilName, "Effort_1_6__c",+value);} }						  
							/>						
						</Grid>
						
					</Grid>	
					
					<Grid item container direction="row"  md = {2} xs={12}>
					
						<Grid item xs = {12}>
							<Typography component="h1" variant="subtitle1"  align = 'center' className = {classes.infoText} >
								Topic test grade 
								<br/> acheived 
							</Typography>					
						</Grid>
						
						<Grid item xs = {12} align = 'center' className = {classes.gridItem}>
							<Autocomplete
							  id="grade-combo"
							  options={["1","2","3","4", "5", "6", "7", "8", "9"]}
							  style={{ width: 150 }}
							  renderInput={(params) => <TextField error={false} {...params} label="Grade" variant="outlined" />}
							  value={ pupilGrade ? "" + pupilGrade : null }
							  onChange = { (event,value)=> {this.props.handleUpdatePupilData(pupilName, "What_grade_did_pupil_achieve__c",+value); } }
							/>						
						</Grid>
						
					</Grid>

					<Grid item container direction = "column" md = {3} xs={12} >
					
						<Grid item>
							<Typography component="h1" variant="subtitle1"  align = 'center' className = {classes.infoText} >
								Reflection on pupil performance <br/>
								(WWW, EBI)
							</Typography>					
						</Grid>
						
						<Grid item align = 'center' className = {classes.gridItem}>
							<TextField	
							  error={ false }
							  variant="outlined"
							  placeholder="Enter text here..."
							  fullWidth
							  multiline								 
							  rows={3}
							  rowsMax={5}
							  inputProps={{ maxLength: 1900}}
							  value={ this.props.pupilData["Pupil_Performance_Summary__c"] }
							  onChange = {(event)=> {this.props.handleUpdatePupilData(pupilName, "Pupil_Performance_Summary__c", event.target.value);} }
							/>				
						</Grid>
						
					</Grid>			

					<Grid item 	container direction = "column" md = {3} xs={12} >
					

						<Grid item >
							<Typography component="h1" variant="subtitle1"  align = 'center' className = {classes.infoText} >
								Reflection on tutor performance.<br/>
								(One thing you will try next week.)
							</Typography>					
						</Grid>
						
						<Grid item align = 'center' className = {classes.gridItem} >
							<TextField
							  error={ false }
							  variant="outlined"
							  placeholder="Enter text here..."
							  fullWidth
							  multiline
							  rows={3}
							  rowsMax={5}
							  inputProps={{ maxLength: 1900}}
							  value={ this.props.pupilData["Action_To_Improve_Performance_of_Pupil__c"] }
							  onChange = {(event)=> { this.props.handleUpdatePupilData(pupilName, "Action_To_Improve_Performance_of_Pupil__c", event.target.value)} }
							/>				
						</Grid>
						
					</Grid>										

				</Grid>
			
			</Paper>
			</Container	>			
		
		)
	}
}

export default withStyles(useStyles)(PupilComponent); 


//
//Setting the firebase config for different projects based on development vs production
//

let FirebaseConfig = null;

if(process.env.NODE_ENV == 'development'){
	FirebaseConfig = {
		apiKey: "AIzaSyBDCc2YcroagNEdBzs-N61OCJoh3KAzB9U",
		authDomain: "testproject-1d184.firebaseapp.com",
		projectId: "testproject-1d184",
		databaseURL: "https://testproject-1d184-default-rtdb.europe-west1.firebasedatabase.app/",
		storageBucket: "testproject-1d184.appspot.com",
		messagingSenderId: "495578349121",
		appId: "1:495578349121:web:54a59803c7021d32b010c0"
	};
}
else{
	FirebaseConfig = {
		apiKey: "AIzaSyBVZ2o8Z9jA718XwuIZz1JzmMWOxtpJ5sA",
		authDomain: "teamupportal-7601c.firebaseapp.com",
		projectId: "teamupportal-7601c",
		databaseURL: "https://teamupportal-7601c-default-rtdb.europe-west1.firebasedatabase.app/",
		storageBucket: "teamupportal-7601c.appspot.com",
		messagingSenderId: "172674708850",
		appId: "1:172674708850:web:e20c6d7a62a1484d8499eb"
	};
}

export {FirebaseConfig}
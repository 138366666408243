import React from 'react';
import ReactDOM from 'react-dom';
import AppBar from "@material-ui/core/AppBar"; 
import Toolbar from "@material-ui/core/Toolbar"; 
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography"; 
import { withStyles } from '@material-ui/core/styles';
import history from '../History';
import FirebaseContext from "../Services/FirebaseContext"; 

const useStyles = (theme) => ({
  
  title: {
    flexGrow: 1,
	color: theme.palette.text.secondary,
  },  
  
  
  logo: {
	marginRight: theme.spacing(2),
    width: 50,
	cursor : "pointer",

  },
  
});



class Topbar extends React.Component{
	
	constructor(props){
		super(props);
				
		this.state = {signOutButtonEnabled: true}; 
		
		this.handleSignOutClick = this.handleSignOutClick.bind(this);
	}
	
	handleSignOutDone(){
		history.push('/Signin');
	}
	
	handleSignOutClick(firebaseHelper){
		
		this.setState({signOutButtonEnabled: false});
		
		firebaseHelper.SignOut()
		
		.then(()=>{
			console.log("SignOut DONE");
			this.setState({signOutButtonEnabled: true});
			this.handleSignOutDone(); 
		})
		
		.catch((error)=>{
			console.log("SignOut ERROR " + error);
			this.setState({signOutButtonEnabled: true});
		}); 
	}
	
	render() {
		
		const {classes} = this.props
	
		return(
		
			<FirebaseContext.Consumer> 
			{ firebaseHelper=>(
				<AppBar position="static"> 
					<Toolbar>						
						<img className={classes.logo} src="logo.png" alt="logo" onClick= {() => history.push('/')}/>
						
						<Typography variant="h6" className={classes.title}> 
							Team Up Form Portal
						</Typography> 
												
						<Button color="inherit" onClick={ ()=>this.handleSignOutClick(firebaseHelper) }>
							Sign Out
						</Button>
																
					</Toolbar> 
				</AppBar>	
			)}
			</FirebaseContext.Consumer> 
		)
	
	}
}

export default withStyles(useStyles)(Topbar); 
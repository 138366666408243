import React from 'react';
import ReactDOM from 'react-dom';
import Typography from "@material-ui/core/Typography"; 
import { withStyles } from '@material-ui/core/styles';
import history from '../History';
import FirebaseContext from "../Services/FirebaseContext"; 

const useStyles = (theme) => ({
    
  welcomeMessage: {
	flexGrow: 1,
	marginBottom: theme.spacing(3),
	
  },
  
});



class HelloTitle extends React.Component{
	
	constructor(props){
		super(props);
				
	}
	
	render() {
		
		const {classes} = this.props
	
		return(
		
			<FirebaseContext.Consumer> 
			{ firebaseHelper=>(
		
				<Typography variant="h7" className={classes.welcomeMessage}> 
					Hello {firebaseHelper.user.email}
				</Typography> 
				
			)}
			</FirebaseContext.Consumer> 
		)
	
	}
}

export default withStyles(useStyles)(HelloTitle); 
import React from 'react';
import ReactDOM from 'react-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from "@material-ui/core/CircularProgress";

//
//loading screen component
//

const useLoadingScreenStyles = makeStyles((theme) => ({
	
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	progresswheel: {
		margin: theme.spacing(1),
	}
  
}));

export default function LoadingScreen(){
	const classes = useLoadingScreenStyles();
	
	return(
		<Container component="main" maxWidth="xs">
		  <CssBaseline />
		  <div className={classes.paper}>
		    <CircularProgress className={classes.progresswheel} />
		  </div>
		</Container>
	);
}


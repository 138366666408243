import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../Components/Copyright.js"
import history from '../History';
import FirebaseContext from "../Services/FirebaseContext"; 
import ResponsiveDialog from "../Components/ResponsiveDialog"; 


const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
  logo: {
	margin: theme.spacing(1),
    width: "50%",
  },
  
});


class SignIn extends React.Component{

	constructor(props){
		super(props);
		
		this.email = "";
		this.password = ""; 
		this.remember = false; 
		
		this.state = {dialogOpen: false, 
					  signinButtonEnabled: true,
					  dialogMessage: "",
					  dialogTitle: ""}; 
		
		this.handleSigninClick = this.handleSigninClick.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleSetEmail = this.handleSetEmail.bind(this);
	}
	
	handleSigninDone(){
		console.log("moving to the homepage (historypush)");
		history.push('/');
	}
	
	handleSigninClick(firebaseHelper){
		
		this.setState({signinButtonEnabled: false});
		
		firebaseHelper.SignInUser(this.email, this.password, this.remember)
		
		.then(()=>{
			console.log("SignIn DONE");
			this.setState({signinButtonEnabled: true});
			
			this.handleSigninDone(); 
		})
		
		.catch((error)=>{
			console.log("SignIn ERROR " + this.email);
			
			this.setState({
				signinButtonEnabled: true,
				dialogOpen: true,
				dialogMessage: "Sorry there seems to be a problem:" + error.message,
				dialogTitle: "SignIn problem!" 
			});
		}); 
	}
	
	handleDialogClose(){
		this.setState({
			dialogOpen: false
		});
	}

	handleSetEmail(email) {
		
		let trimEmail = email.trim();
		
		this.email = trimEmail;
		
	}

	render(){
		
	  const {classes} = this.props


	  return (
	  
		<Container component="main" maxWidth="xs">
		  <CssBaseline />
		  <div className={classes.paper}>
		  <img className = {classes.logo} src="logo.png" alt="Team Up logo" />
			<Avatar className={classes.avatar}>
			  <LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
			  Sign in
			</Typography>
			<form className={classes.form} noValidate>
			  <TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="email"
				label="Email Address"
				name="email"
				autoComplete="email"
				autoFocus
				onChange={(e) => this.handleSetEmail(e.target.value)}
			  />
			  <TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				name="password"
				label="Password"
				type="password"
				id="password"
				autoComplete="current-password"
				onChange={(e) => this.password = e.target.value}
			  />
			  <FormControlLabel
				onChange = {(e) => this.remember = e.target.checked}
				control={<Checkbox value="remember" color="primary" />}
				label="Remember me"
			  />
			  
			  <FirebaseContext.Consumer>
			  { firebaseHelper=>(
				  <Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					disabled={!this.state.signinButtonEnabled}
					onClick={()=>this.handleSigninClick(firebaseHelper)}
				  >
					Sign In
				  </Button>
			  )}
			  </FirebaseContext.Consumer>
			  
			  <ResponsiveDialog open={this.state.dialogOpen} 
								handleClose={this.handleDialogClose}
								message={this.state.dialogMessage}
								title={this.state.dialogTitle}/>
			  
			  
			  <Grid container>
				<Grid item xs>
				  <Link variant="body2" to= "/Resetpassword" onClick={() => history.push('/Resetpassword')} >
					Forgot password?
				  </Link>
				</Grid>
				<Grid item>
				  <Link variant="body2" to= "/Signup" onClick={() => history.push('/Signup')}>
					{"Don't have an account? Sign Up"}
				  </Link>
				</Grid>
			  </Grid>
			</form>
		  </div>
		  <Box mt={8}>
			<Copyright />
		  </Box>
		</Container>
		
	  );
	  
	}
}

export default withStyles(useStyles)(SignIn)

import { Router, Switch, Route } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';

import Home from './Pages/Home';
import SubmissionSuccess from './Pages/SubmissionSuccess';
import SignUp from './Pages/SignUp';
import SignIn from './Pages/SignIn';
import ResetPassword from './Pages/ResetPassword';
import ChangePassword from './Pages/ChangePassword';
import VerifyEmail from './Pages/VerifyEmail';

import PRRForm from './Forms/PRRForm/PRRForm';

import PrivateRoute from "./Components/PrivateRoute";

import history from './History';

export default class Routes extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <PrivateRoute path="/" exact={true} component={Home} />
					<PrivateRoute path="/PerformanceReviewReport" component={PRRForm} />
					<PrivateRoute path="/SubmissionSuccess" component={SubmissionSuccess} />
					<Route path="/Signin" component={SignIn} />
                    <Route path="/Signup" component={SignUp} />
					<Route path="/Resetpassword" component={ResetPassword} />
					<Route path="/Changepassword" component={ChangePassword} />
					<Route path="/Verifyemail" component={VerifyEmail} />
                </Switch>
            </Router>
        )
    }
}
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../Components/Copyright.js"
import history from '../History';
import FirebaseContext from "../Services/FirebaseContext"; 
import Misc from "../Services/Misc"; 
import ResponsiveDialog from "../Components/ResponsiveDialog"; 


const useStyles = (theme) => ({
  paper: {
	marginTop: theme.spacing(8),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
  },
  
  header: {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	
  },
  avatar: {
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
  },
  form: {
	width: '100%', // Fix IE 11 issue.
	marginTop: theme.spacing(3),
  },
  submit: {
	margin: theme.spacing(3, 0, 2),
  },
  
  logo: {
	margin: theme.spacing(1),
    width: "50%",
  },

  
}); 


class SignUp extends React.Component{

	constructor(props){
		super(props);
		
		this.email = "";
		this.password = "";		
		
		this.state = {dialogOpen: false, 
					  signupButtonEnabled: true,
					  dialogMessage: "",
					  dialogTitle: ""}; 
		
		this.handleSignupClick = this.handleSignupClick.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleSetEmail = this.handleSetEmail.bind(this);
	}
	
	handleSignupDone(){
		history.push('/Verifyemail');
	}
	
	handleSignupClick(firebaseHelper){
		
		this.setState({signupButtonEnabled: false});
		
		firebaseHelper.SignUpUser(this.email, this.password, this.password)
		
		.then(()=>{
			console.log("SignUpUser DONE");
			this.setState({signupButtonEnabled: true});
			
			this.handleSignupDone(); 
			
		})
		
		.catch((error)=>{
			console.log("SignUpUser ERROR " + error);
			
			this.setState({
				signupButtonEnabled: true,
				dialogOpen: true,
				dialogMessage: "Sorry there seems to be a problem:" + error.message,
				dialogTitle: "Sign up problem!" 
			});
		}); 
	}
	
	handleDialogClose(){
		this.setState({
			dialogOpen: false
		});
	}
	
	handleSetEmail(email) {
		
		let trimEmail = email.trim();
		
		this.email = trimEmail;
		
	}	

	render(){
		
	  const {classes} = this.props

	  return (
		<Container component="main" maxWidth="xs">
		  <CssBaseline />
		  <div className={classes.paper}>
			<img className = {classes.logo} src="logo.png" alt="Team Up logo" />
			<Avatar className={classes.avatar}>
			  <LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
			  Sign up
			</Typography>
			<form className={classes.form} noValidate>
			  <Grid container spacing={2}>
				<Grid item xs={12}>
				  <TextField
					variant="outlined"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					onChange={(e) => this.handleSetEmail(e.target.value)}
				  />
				</Grid>
				
				<Grid item xs={12}>
				  <TextField
					variant="outlined"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					onChange={(e) => this.password = e.target.value}
				  />
				</Grid>
			  </Grid>
			  
			  
			  <FirebaseContext.Consumer>
			  { firebaseHelper=>(
				  <Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					disabled={!this.state.signupButtonEnabled}
					className={classes.submit}
					onClick={()=>this.handleSignupClick(firebaseHelper)}
				  >
					Sign Up
				  </Button>
				)
			  }
			  </FirebaseContext.Consumer>			  
			  
			  <ResponsiveDialog open={this.state.dialogOpen} 
								handleClose={this.handleDialogClose}
								message={this.state.dialogMessage}
								title={this.state.dialogTitle}/>
			  
			  <Grid container justify="flex-end">
				<Grid item>
				  <Link variant="body2" to= "/Signin" onClick={() => history.push('/Signin')}>
					Already have an account? Sign in
				  </Link>
				</Grid>
			  </Grid>
			</form>
		  </div>
		  <Box mt={5}>
			<Copyright />
		  </Box>
		</Container>
	  );
	  
	}
}

export default withStyles(useStyles)(SignUp)
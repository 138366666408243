//React
import React from 'react';
import ReactDOM from 'react-dom';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

//Internal
import history from '../../History';
import FirebaseContext from "../../Services/FirebaseContext"; 
import Topbar from "../../Components/Topbar.js";
import HelloTitle from "../../Components/HelloTitle.js";
import LoadingScreen from "../../Components/LoadingScreen";
import ResponsiveDialog from "../../Components/ResponsiveDialog"; 
import Misc from "../../Services/Misc"; 

//Form parts
//import TopicMapping from './TopicMapping';
import useStyles from "./Style"
import PupilComponent from "./PupilComponent"


class PRRForm extends React.Component{

	static contextType = FirebaseContext; 

	constructor(props){
		super(props);
				
		this.state = {formData: "none",
					  dialogOpen: false,
					  dialogMessage: "",
					  dialogTitle: "",
					  sessiontopics: [], 
					  subjectAndKeystage: null,
					  subjectAndKeystageError: false,
					  todaysLesson: null, 
					  todaysLessonError: false,
					  nextLesson: null, 
					  nextLessonError: false, 
				      pupilDatas: [],
					  topicMapping : {}
		};
		
		this.sNumber = "";
				
		//this.topicMapping = TopicMapping.topicMapping;
						
		//bind functions
		this.handleGetFormDataClick = this.handleGetFormDataClick.bind(this);
		
		this.handleDialogClose = this.handleDialogClose.bind(this);
		
		this.handleSubjectChange  = this.handleSubjectChange.bind(this);
		
		this.handleDialogOpen = this.handleDialogOpen.bind(this);
		
		this.handleUpdatePupilData = this.handleUpdatePupilData.bind(this);
		
		this.handleSubmission = this.handleSubmission.bind(this);
		
	}

	//on mount
	componentDidMount(){
		console.log("PRR mounted");
		const firebaseHelper = this.context;
		console.log('getting topic mapping')
		firebaseHelper.getSubjectTopicMapping()
		.then((mappings)=>{
			if(mappings){
				this.setState({topicMapping:mappings})
			}
		})
	}
	
	//component for snumber input
	sNumberComponent () {
		const {classes} = this.props
		
		return (
			<FirebaseContext.Consumer> 
			{ firebaseHelper=>(
				
			<Container component="main" maxWidth="xs">
			
			<Typography component="h2" variant="subtitle1"  align = 'center' className = {classes.infoText}>
				Please input your five digit session code
			</Typography>		
			
				<Grid container  direction="row" alignItems="center" justify="center" spacing={1} >
				
					<Grid item >	
						<Typography component="h2" variant="subtitle1"  >
						S-
						</Typography>
					</Grid>
					
					<Grid item >				
						<form autoComplete="off">
							<TextField
								variant="outlined"
								required
								id="standard-number"
								type = "number"
								label="Session code"
								name="sNumber"
								autoFocus
								onChange={(e) => this.sNumber = "S-" + e.target.value }
							/>
						</form>
					</Grid>
					
					<Grid item >
					<Button 
						onClick={ ()=>this.handleGetFormDataClick(firebaseHelper, this.sNumber) }
						type="button"
						variant="contained"
						color="primary"> 
						Submit 
					</Button>
					</Grid>
					
				 </Grid>	
				 </Container>
			)}
			</FirebaseContext.Consumer> 
			)
	}
	
	
	//main form component
	mainFormComponent() {
		
		const {classes} = this.props
		return (
		
			<FirebaseContext.Consumer> 
			{ firebaseHelper=>(
			<div>
			<Container  maxWidth="md"> 
		
					<Typography component="h1" variant="h5"  align = 'center' className = {classes.title}>
						Performance Review Report
					</Typography>					
		
					<Grid container alignItems="center" justify="center" spacing={1} xs = {12} >
					
							<Grid item xs = {12}>
								<Typography component="h2" variant="subtitle1"  align = 'center' className = {classes.infoText}>
									Key Stage and Subject
								</Typography>							
							</Grid>
							
							<Grid item xs = {12} align = 'center'>
								<Autocomplete
								  id="key-stage-combo"
								  options={this.state.topicMapping?.subjectAndKeystage ?? []}
								  style={{ width: 300 }}
								  value={this.state.subjectAndKeystage}
								  renderInput={(params) => <TextField error={this.state.subjectAndKeystageError} {...params} label="Keystage and subject" variant="outlined" />}
								  onChange = {(event,value)=> this.handleSubjectChange(value)}
								/>							
							</Grid>
						
					</Grid>
						

						
					<Grid container alignItems="center" justify="center" spacing={1}  xs = {12}>
					
						<Grid item xs = {12}>
							<Typography component="h2" variant="subtitle1"  align = 'center' className = {classes.infoText}>
								Today’s Lesson Topic
							</Typography>							
						</Grid>
						
						<Grid item xs = {12} align = 'center'>
							<Autocomplete
							  id="todays-lesson-combo"
							  options={this.state.sessiontopics}
							  style={{ width: 300 }}
							  value={this.state.todaysLesson}
							  renderInput={(params) => <TextField error={this.state.todaysLessonError} {...params} label="Today's lesson" variant="outlined" />}	
							  onChange = {(event,value)=> {this.setState({todaysLesson: value, todaysLessonError: false}); }}
							/>								
						</Grid>	
					
					</Grid>
						

						
					<Grid container  direction="row" alignItems="center" justify="center" spacing={1} xs = {12} >
					
						<Grid item xs = {12}>
							<Typography component="h2" variant="subtitle1"  align = 'center' className = {classes.infoText}>
								Next Week’s Lesson Topic
							</Typography>							
						</Grid>
						
						<Grid item xs = {12} align = 'center'>
							<Autocomplete
							  id="next-lesson-combo"
							  options={this.state.sessiontopics}
							  style={{ width: 300 }}
							  value={this.state.nextLesson}
							  renderInput={(params) => <TextField error={this.state.nextLessonError} {...params} label="Next lesson" variant="outlined" />}
							  onChange = {(event,value)=> this.setState({nextLesson : value, nextLessonError: false})}
							/>								
						</Grid>	
					
					</Grid>							

					<Divider variant="middle" className = {classes.divider}/>
					
					</Container>
					
					{this.state.pupilDatas.map ((pupilData)=>(
						<div key={pupilData["Pupil_Reference__c"]}> 
							<PupilComponent pupilData={pupilData} 
											handleUpdatePupilData={this.handleUpdatePupilData}/>
						</div>
					))}
					
					<Grid container alignItems="center" justify="center" spacing={2} >
					
						<Grid item>
						
							<Button 
								type="button"
								variant="contained"
								color="primary"
								className = {classes.button}
								onClick = {()=> this.handleSubmission(firebaseHelper)}
							>
							 
								Submit PRR
								
							</Button>	
							
						</Grid>
						
					</Grid>
			
				</div>		
				)}
				</FirebaseContext.Consumer> 				
				)
		
	}
	
	//handle click for get for data
	handleGetFormDataClick(firebaseHelper, sNumber){
		
		let IsSNumber = Misc.IsSNumber(sNumber);
		
		if (IsSNumber) {
			
			this.setState({formData: "requesting"});
			
			firebaseHelper.GetFormData("PerformanceReviewReport", sNumber)
				
			.then( (result)=> {
				
				console.log(JSON.stringify(result));
								
				//Build the pupil data
				let pds = [];
				
				if ( result !== 'null' && result !== 'undefined' ) {
					
					let tutorId = result['data']['tutorId'];
					console.log("tid",tutorId);
					let sessionId = result['data']['sessionId'];
					console.log("sid",sessionId);
					let participants = result['data']['participants'];
															
					for (let i = 0; i < participants.length; i++){
						
						let pupilName = participants[i]['Pupil_Name__c'];
						let pupilId = participants[i]['Pupil__c'];
						let programmeId = participants[i]['Programme__c'];
						let ppId = participants[i]['Id'];
					
						let pd = {}; 
						pd["Pupil_Name__c"] = pupilName;
						pd["RecordTypeId"] = '01224000000EqcdAAC'; 
						pd["Pupil_Reference__c"] = pupilId; 
						pd["Programme_Participation__c"] = ppId; 
						pd["Tutor__c"] = tutorId; 
						pd["Programme__c"] = programmeId; 
						pd["Session__c"] = sessionId; 
						pd["Effort_1_6__c"] = null; 
						pd["What_grade_did_pupil_achieve__c"] = null; 
						pd["Pupil_Performance_Summary__c"] = ''; 
						pd["Action_To_Improve_Performance_of_Pupil__c"] = ''; 
						pds.push(pd);
					}
				}
				
				this.setState({pupilDatas: pds}); 
				
				setTimeout( ()=> this.setState({formData: "received"}), 500)
			})
			
			.catch((error)=>{
				console.log("Error getting form data " + error);
				//console.log("details:" + error.details.rawError); 
				this.handleDialogOpen("Error getting form data", error.message);
				this.setState({formData: "none"});
			}); 
		
		}
		
		else {
			
			this.handleDialogOpen("Invalid session code","Please enter a valid 5 digit session code");
			this.setState({formData: "none"});	 
		}
	
		
	}
	
	//open error dialog
	handleDialogOpen(title, message) {
		
		this.setState({			
			  dialogOpen: true,
			  dialogMessage: message,
			  dialogTitle: title,									
		});	
		
	}
	
	handleDialogClose(){
		
		this.sNumber = "";
		
		this.setState({
			dialogOpen: false,
		});
	}
	
	handleSubjectChange(value){
		
		console.log("value: " + value);
		
		//null out the lessons combos
		this.setState({todaysLesson: null, nextLesson:null});
		
		if (value !== null && value !== undefined) {
			
			//update subject
			this.setState({subjectAndKeystage: value, subjectAndKeystageError: false});
						
			//get list of topics
			let topics = this.state.topicMapping?.topics[value] ?? [];
			
			//update session topic state with topics
			this.setState({
				sessiontopics: topics
			});							
		}	

		else {
			
			this.setState({subjectAndKeystage: null, subjectAndKeystageError: true});
			
			this.setState({
				sessiontopics: []
			});								
		}
	}
	
	handleUpdatePupilData(pupilName, key, value) {
		
		
		let newPupilDatas = this.state.pupilDatas.map(pupilData => {
		
			if (pupilData["Pupil_Name__c"] === pupilName) {
				pupilData[key] = value;
			} 
			return pupilData; 
			
		});
								
		this.setState({pupilDatas: newPupilDatas});
	}	
	
	handleSubmission(firebaseHelper) {
		
		//
		//check input is good
		//
		let dataValid = true;
		
		if(!(this.state.subjectAndKeystage)){
			this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
			dataValid = false; 
			this.state.subjectAndKeystageError = true; 
			console.log("subjectAndKeystage fill error");
		}
		
		if(!this.state.todaysLesson){
			this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
			dataValid = false;
			this.state.todaysLesson = null; 
			this.state.todaysLessonError = true; 
			console.log("todaysLesson fill error");
		}
		
		/*//Next lesson is not essential !
		if(!this.state.nextLesson){
			this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
			dataValid = false;
			this.state.nextLesson = null; 
			this.state.nextLessonError = true; 
			console.log("nextLesson fill error");
		}*/
		
		let pupilCounter = 0; 
		this.state.pupilDatas.forEach( pupil =>{
			
			let pupilEffort = pupil["Effort_1_6__c"]
			let pupilGrade = pupil["What_grade_did_pupil_achieve__c"]; 
			let pupilPerformance = pupil["Pupil_Performance_Summary__c"]; 
			let pupilTutorPerformance = pupil["Action_To_Improve_Performance_of_Pupil__c"]; // yes this is the tutor field, despite the name
			
			let someDataOnPupil = pupilEffort || pupilGrade || pupilPerformance || pupilTutorPerformance;
			
			if(someDataOnPupil){
				
				/*
				//console.log(JSON.stringify(value));
				if(!pupilEffort){
					this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
					dataValid = false;
					console.log("effort fill error");
				}
				
				if(!pupilGrade){
					this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
					dataValid = false;
					console.log("grade fill error");
				}
				
				if(!pupilPerformance){
					this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
					dataValid = false;
					console.log("pperformance fill error");
				}
				
				if(!pupilTutorPerformance){
					this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
					dataValid = false;
					console.log("tperformace fill error");
				}
				*/
				pupilCounter++; 
			}
		}); 
		
		//if there are no pupils filled in its invalid
		if(pupilCounter <= 0){
			this.handleDialogOpen("Form Entry Error", "Make sure you fill everything in correctly.");
			dataValid = false; 
			console.log("pupilCounter==0");
		}
		
		//
		//Build response
		//
		if(dataValid) {
			
			//build array of assessment objs for SF 
			const assessments = this.buildFormResponse();
			
			//go to loading screen
			this.setState({formData: "requesting"});
			
			firebaseHelper.SubmitForm("PerformanceReviewReport", assessments)
				
			.then( (result)=> {
				
				console.log(result);
				setTimeout( ()=> { history.push('/SubmissionSuccess'); }, 500);
			})
			
			.catch((error)=>{
				
				console.log("Error submitting form data " + JSON.stringify(error) );
				this.handleDialogOpen("Error submitting form data", error.message);
				
				this.setState({formData: "none"});
			}); 
		}
	}
	
	buildFormResponse() {
		
		let assessments = [];
		
		this.state.pupilDatas.forEach( pupil =>{
			
			let asmnt = {}; 
			
			//per pupil input
			let pupilEffort = pupil["Effort_1_6__c"]
			let pupilGrade = pupil["What_grade_did_pupil_achieve__c"]; 
			let pupilPerformance = pupil["Pupil_Performance_Summary__c"]; 
			let pupilTutorPerformance = pupil["Action_To_Improve_Performance_of_Pupil__c"]; // yes this is the tutor field, despite the name
			
			let pupilHasData = pupilEffort || pupilGrade || pupilPerformance || pupilTutorPerformance;
			if(pupilHasData){//Check if pupil data is there
			
				asmnt["Effort_1_6__c"] = pupilEffort;
				asmnt["What_grade_did_pupil_achieve__c"] = pupilGrade;
				asmnt["Pupil_Performance_Summary__c"] = pupilPerformance;
				asmnt["Action_To_Improve_Performance_of_Pupil__c"] = pupilTutorPerformance;
				
				//ids and names
				asmnt["RecordTypeId"] = pupil["RecordTypeId"];
				asmnt["Pupil_Reference__c"] = pupil["Pupil_Reference__c"];
				asmnt["Programme_Participation__c"] = pupil["Programme_Participation__c"];
				asmnt["Tutor__c"] = pupil["Tutor__c"];
				asmnt["Programme__c"] = pupil["Programme__c"];
				asmnt["Session__c"] = pupil["Session__c"];
				
				
				//add lesson topics to responses
				asmnt["This_Subject_and_Key_Stage__c"] = this.state.subjectAndKeystage;
				asmnt["This_Lesson_Topic__c"] = this.state.todaysLesson;
				asmnt["Next_Lesson_Topic__c"] = this.state.nextLesson;
				
				//add pupile data to asmnt
				assessments.push(asmnt);
			}
		}); 
		
		return assessments;
		
	}

	
	//render components based on state
	renderComponent() {
		
		let currentState = this.state.formData;
		
		if (currentState === "none") {
			
			return this.sNumberComponent()
					
		}
		
		else if (currentState === "requesting") {
			
			return <LoadingScreen />
		
		}
		
		else if (currentState === "received") {
			
			return this.mainFormComponent()
			
		}
	}
	
	render(){
		
	  const {classes} = this.props

		return ( 

				<div className={classes.root}> 
				
					<CssBaseline />
					
					<Topbar />
					
				    <div className = {classes.paper}>				
					
						{this.renderComponent()}

					</div>
					
				<ResponsiveDialog open={this.state.dialogOpen} 
								handleClose={this.handleDialogClose}
								message={this.state.dialogMessage}
								title={this.state.dialogTitle}/>					
				  
				</div>		 
		
			
		)
	}
}

export default withStyles(useStyles)(PRRForm); 

import { Router, Switch, Route, Redirect } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';

import FirebaseHelper from "../Services/FirebaseHelper"; 
import FirebaseContext from "../Services/FirebaseContext"; 

import LoadingScreen from "./LoadingScreen";

//
//Private route class 
//

export default class PrivateRoute extends React.Component{
	
	static contextType = FirebaseContext; 
	
	constructor(props){
		super(props); 
		this.state = {auth: FirebaseHelper.AUTHING}; 
	}
	
	componentDidMount(){
		
		const firebaseHelper = this.context;
		
		this.setState({auth: firebaseHelper.GetAuthed()}); 
		
		firebaseHelper.AddAuthChangeCallback( (authed)=>{
			setTimeout(()=>{
				
				this.setState({auth: authed}); 
				
			}, 500);
		}); 
	}
	
	componentWillUnmount(){
		
	}
	
	render(){
	
		let routeRender = null;
		
		if(this.state.auth === FirebaseHelper.AUTHED){
			let NewComponent = this.props.component; 
			routeRender = ()=>{return (<NewComponent/>)}; 
		}
		
		else if(this.state.auth === FirebaseHelper.AUTHING){
			routeRender = ()=>{return(<LoadingScreen />)}; 
		}
		
		else {
			routeRender = ()=>{return(<Redirect to={ {pathname: '/Signin' } }/>)}; 
		}
		
		
		return (
			<Route path={this.props.path} exact={this.props.exact} render={ routeRender }/>
		);
	}
}
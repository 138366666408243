import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography"; 
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import history from '../History';
import FirebaseContext from "../Services/FirebaseContext"; 
import Topbar from "../Components/Topbar.js";



const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  avatar: {
	margin: theme.spacing(4),
	backgroundColor: theme.palette.secondary.main,
	
  },	
  
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  
  title: {
	color: theme.palette.text.secondary,

  },

});


class SubmissionSuccess extends React.Component{

	constructor(props){
		
		super(props);
	
	}
	

	render(){
		
	  const {classes} = this.props

		return ( 
				<div className={classes.root}> 	
				
					<CssBaseline />
					
					<Topbar />
					
					<Container component="main" maxWidth="xs" alignItems = "center">					
					  
					  <div className={classes.paper}>
								
						<Avatar className={classes.avatar}>
							<CheckCircleOutlineIcon />
						</Avatar>
						
						<Typography component="h1" variant="h4" align = 'center' className={classes.title}>
							Form submitted successfully!
						</Typography>
						

					  </div>

					</Container>
				
				</div>
		)
	}
}

export default withStyles(useStyles)(SubmissionSuccess); 

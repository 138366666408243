//requirments
//import firebase from "firebase";
import Misc from "./Misc"; 
import {
    initializeApp, 
} from 'firebase/app';

import {
    getAuth, 
    getIdToken,
    sendSignInLinkToEmail,
    isSignInWithEmailLink, 
    signInWithEmailLink,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    browserLocalPersistence, 
    browserSessionPersistence, 
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
    signInWithRedirect,
} from 'firebase/auth';

import { 
    getDatabase, 
    ref, 
    set, 
    get, 
    update, 
    onValue, 
    query as rtQuery,
    off } from 'firebase/database';

import {FirebaseConfig} from "../Config"; 

//
//Auth enum values
//



export default class FirebaseHelper {
	
	static AUTHED = 2;
	static AUTHING = 1;
	static NOTAUTHED = 0;
	static API_URL = process.env.NODE_ENV === 'development' ? "https://us-central1-testproject-1d184.cloudfunctions.net" : "https://us-central1-teamupportal-7601c.cloudfunctions.net"
	
	//
	//Constructor
	//
	
	constructor() {
		console.log("FirebaseHelper project " + FirebaseConfig.projectId);
		
		this.app = initializeApp(FirebaseConfig);

		//Init app
		/*if(!firebase.apps.length)
			firebase.initializeApp(FirebaseConfig);*/
		
		//setup
		this.authed = FirebaseHelper.AUTHING;
		this.idtoken = null; 
		this.user = null; 
		
		//authchange callback
		this.authChangeCallbacks = []; 
		let CallAuthChangedCallbacks = ()=>{
			for(let i = 0; i < this.authChangeCallbacks.length; i++){
				if(this.authChangeCallbacks[i]){
					this.authChangeCallbacks[i](this.authed); 
				}
			}
		}
	
		//Auth state change
		
		let OnAuthChange = (user) =>{
			console.log("AUTH CHANGE CALLBACK");
			if (user) {
				if(user.emailVerified){
					console.log("AUTH CHANGE CALLBACK EMAIL VERIFIED!");
					// User is signed in, get token and set variables
					user.getIdToken()
					
					//get token
					.then((token)=>{
						
						this.authed = FirebaseHelper.AUTHED;
						this.user = user; 
						this.idtoken = token; 
						
						CallAuthChangedCallbacks(); 
					})
					
					//error
					.catch((error) =>{
						
						this.authed = FirebaseHelper.NOTAUTHED; 
						this.idtoken = null; 
						this.user = null; 	
						
						CallAuthChangedCallbacks(); 
					}); 
				}
				else{
					console.log("AUTH CHANGE CALLBACK EMAIL NOT VERIFIED");
						// No user is signed in.
					this.authed = FirebaseHelper.NOTAUTHED; 
					this.idtoken = null; 
					this.user = null; 	
					
					CallAuthChangedCallbacks(); 
					/*
					//Hack to get the system to wait until email has been verified on an interval and recall onauthchange
					let intervalID = null;
					intervalID = setInterval( ()=>{
						console.log("AUTH CHANGE CALLBACK EMAIL VERIFY CHECK INTERVAL:" + intervalID + " "  + user);
						if(user.emailVerified && intervalID){
							console.log("AUTH CHANGE CALLBACK EMAIL VERIFY CHECK INTERVAL DONE!");
							clearInterval(intervalID);
							OnAuthChange();
						}
					}, 500);
					*/
				}
				
			} else {
				console.log("AUTH CHANGE CALLBACK NO USER");
				// No user is signed in.
				this.authed = FirebaseHelper.NOTAUTHED; 
				this.idtoken = null; 
				this.user = null; 	
				
				CallAuthChangedCallbacks(); 
			}
		};
		
		//firebase.auth().onAuthStateChanged( OnAuthChange );
		getAuth().onAuthStateChanged( OnAuthChange );
	}

	//
	// Token
	//
	async getUserToken(){
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        return token;
    }


	//
	//Sign in a user 
	//
	
	SignInUser(email, password, isRemembered=false) {
		
		const signindata = {email: email, password: password};
		
		//create new promise and test sign up data is valid
		return new Promise((resolve, reject)=>{
			
			const validObj = Misc.ValidateSignInData(signindata);
			if(validObj.valid)
				resolve(signindata); 
			else
				reject({code:"SignInDataNotValid", message:"you did not provide a valid email or password", signindata:signindata});
			
		})
		
		//set up persitance or not
		.then((signindata)=>{
			let auth = getAuth(); /*firebase.auth()*/; 
			let fbState; 
			if(isRemembered){
				fbState = browserLocalPersistence/*firebase.auth.Auth.Persistence.LOCAL*/;
			}
			else {
				fbState = browserSessionPersistence /*firebase.auth.Auth.Persistence.SESSION*/;
			}
			
			console.log("fbState:" + fbState);
			
			return auth.setPersistence(fbState);
		})
		
		//run sign in
		.then(()=>{
			const auth = getAuth(); /*firebase.auth()*/; 
			console.log("sign in attempt:");
			return signInWithEmailAndPassword(auth, signindata.email, signindata.password);
		})	
		
		//check if email verified and signout if not verified
		.then((userCredential) =>{
			if(!userCredential.user.emailVerified ){
				const auth = getAuth(); /*firebase.auth()*/; 
				signOut(auth);
				/*auth.signOut();*/
				throw new Error ({code: "EmailNotVerifiedYet", message: "you have not verified your email yet"}); 
			}
			else{
				console.log("email valid:");
				return true; 
			}
		})
		
		.catch((error) => {
			
			console.log("error message:" + error.message); 
			
			//rethrow to continue the promise chain
			throw error; 
		}); 
	}


	//
	//Signup a new user
	//
	
	SignUpUser(email, password, confirmPassword) {
		
		const signupdata = {email: email, password: password, confirmPassword: confirmPassword};
		
		//create new promise and test sign up data is valid
		return new Promise((resolve, reject)=>{
			
			const validObj = Misc.ValidateSignUpData(signupdata);
			if(validObj.valid)
				resolve(signupdata); 
			else
				reject({code:"SignUpDataNotValid", message:"you did not provide a valid email or password",  signupdata:signupdata});
			
		})
		
		//run create new user
		.then((signupdata)=>{
			const auth = getAuth(); /*firebase.auth()*/
			return createUserWithEmailAndPassword(auth,signupdata.email, signupdata.password); 
			/*auth.createUserWithEmailAndPassword(signupdata.email, signupdata.password);*/
		})		
		
		//send the email verification and set variables
		.then((data)=>{
			
			//send email and then return data with user and token 
			return sendEmailVerification(data.user); /*data.user.sendEmailVerification();*/
		})
		
		////force signout
		.then((data)=>{
			const auth = getAuth() /*firebase.auth();*/ 
			return signOut(auth); /*auth.signOut();*/
		})
		
		.catch((error) => {
			
			//rethrow error to pass on to the next catch statment if needed
			throw error; 
		});
	}

	//
	//Resend email verification
	//
	
	ResendEmailVerification(email, password){
		
		const signindata = {email: email, password: password};
		
		//create new promise and test sign up data is valid
		return new Promise((resolve, reject)=>{
			
			const validObj = Misc.ValidateSignInData(signindata);
			if(validObj.valid)
				resolve(signindata); 
			else
				reject({code:"SignInDataNotValid", message:"you did not provide a valid email or password",  signindata:signindata});
			
		})
		
		.then((signindata)=>{
			if(!this.authed === FirebaseHelper.AUTHED){
				return signindata; 
			}
			else{
				throw {code: "EmailAlreadyVerified", message: "This email has already been verified"}; 
			}
		})
		
		//run sign in
		.then((signindata)=>{
			const auth = getAuth(); /*firebase.auth();*/
			return signInWithEmailAndPassword(auth,signindata.email, signindata.password);
			//return auth.signInWithEmailAndPassword(signindata.email, signindata.password);
		})	
		
		//check if email verified and signout if not verified
		.then((userCredential) =>{
			if(!userCredential.user.emailVerified ){
				return sendEmailVerification(userCredential.user);
			}
			else{
				throw new Error({code: "EmailAlreadyVerified", message: "This email has already been verified"}); 
			}
		})
		
		.then(()=>{
			const auth = getAuth();
			signOut(auth);
		}) 
		
		.catch((error) => {
			//rethrow error to pass on to the next catch statment if needed
			throw error; 
		});
	}
	
	
	//
	//Email Password Reset
	//
	
	SendPasswordResetEmail(email) {
		
		//create new promise and validate email
		return new Promise((resolve, reject)=>{
			
			const validObj = Misc.IsEmail(email);
			if(validObj)
				resolve(email); 
			else
				reject({code:"EmailNotValid", message:"you did not provide a valid email", email:email});
			
		})
		
		//send email validation
		.then((email)=>{
			const auth = getAuth(); /*firebase.auth();*/
			return  sendPasswordResetEmail(auth, email); /*auth.sendPasswordResetEmail(email);*/
		})
		
		//catch errors and then pass on
		.catch((error) => {
			
			
			throw error; 
		});
	}
	
	
	//
	//Change password
	//
	
	ChangePassword(newPassword) {
		
		//create new promise and chek if loged in and valid uiser object
		return new Promise((resolve, reject)=>{
			
			if(this.authed === FirebaseHelper.AUTHED && this.user){
				resolve(newPassword); 
			}
			else{
				reject({code:"NotSignedIn", message:"you are not signed in", password:newPassword}); 
			}
			
		})
		
		//Do the actual update
		.then((pwd)=>{
			return this.user.updatePassword(pwd); 
		})
			
		//catch and pass on
		.catch(function(error) {
			throw error; 
		});
	}
	
	async isAdmin() {
		const user = getAuth().currentUser;
		const uid = user.uid;
		const db = getDatabase()
		const dbRef = ref(db,`admin/${uid}`) /*firebase.database().ref()*/;
		const snapshot = await get(dbRef) /*dbRef.child("admins").child(uid).get()*/;
		if (snapshot.exists() && snapshot.val() === "admin"){
			return true;
		}
		return false;
	}
	
	//
	//Sign Out
	//
	
	SignOut(){
		
		//Signout firebase function
		const auth = getAuth(); /*firebase.auth();*/
		
		/*return auth.signOut()*/
		return signOut(auth)
		.then(() => {
		  // Sign-out successful.
		  return true; 
		})
		
		//
		.catch((error) => {
			throw error; 
		});

	}
	
	
	//
	//Check Auth situation
	//
	
	CheckIsAuthed(){
		return this.authed === FirebaseHelper.AUTHED; 
	}
	
	CheckIsAuthing(){
		return this.authed === FirebaseHelper.AUTHING; 
	}
	
	CheckNotAuthed(){
		return this.authed === FirebaseHelper.NOTAUTHED; 
	}
	
	GetAuthed(){
		return this.authed; 
	}
	
	
	//
	//Auth Change callbacks; 
	//
	
	AddAuthChangeCallback(callback){
		this.authChangeCallbacks.push(callback); 
	}
	
	RemoveAuthChangeCallback(callback){
		const index = this.authChangeCallbacks.indexOf(callback);
		if (index > -1) {
			this.authChangeCallbacks.splice(index, 1);
		}
	}
	
	
	//
	//API
	//
	
	async GetFormData(formType, snumber){
		//Lauch firebase function retuirning promise
		//let fbFunc = firebase.functions().httpsCallable('getFormData');
		//return fbFunc({ formType: formType, snumber: snumber }); 
		const token = await this.getUserToken();
		try{
			const res = 
			await fetch( `${FirebaseHelper.API_URL}/getFormData`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify({ formType: formType, snumber: snumber }),
			});

			const data = await res.json();
			if(!data.ok){
				throw new Error(data.message);
			}
			return data;
		}
		catch(error){
			console.error(error.message);
			throw error;
		}
	}
	
	async SubmitForm(formType, assessments){
		//Lauch firebase function retuirning promise
		//let fbFunc = firebase.functions().httpsCallable('submitForm');
		//return fbFunc({ formType: formType, assessments: assessments }); 
		const token = await this.getUserToken();
		try{
			const res = 
			await fetch( `${FirebaseHelper.API_URL}/submitForm`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify({ formType: formType, assessments: assessments }),
			});

			const data = await res.json();
			console.log(JSON.stringify(data))
			if(!data.ok){
				throw new Error(data.message);
			}
			return data;
		}
		catch(error){
			console.error(error.message);
			throw error;
		}
	}

	async GetTopicMapping(){
		// Lauch firebase function retuirning promise
		// let fbFunc = firebase.functions().httpsCallable('getTopicMapping');
		// return fbFunc(); 
		const token = await this.getUserToken();
		try{
			const res = 
			await fetch( `${FirebaseHelper.API_URL}/getTopicMapping`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify({}),
			});

			const data = await res.json();
			console.log(JSON.stringify(data))
			if(!data.ok){
				throw new Error(data.message);
			}
			return data;
		}
		catch(error){
			console.error(error.message);
			throw error;
		}
	}

	async getSubjectTopicMapping() {
		console.log("getting subject topic mapping");
		const db = getDatabase();
		const r = ref(db,'topicMapping');
		const snapshot = await get(r);
		if (snapshot.exists()){
			console.log("snapshot exists");
			return snapshot.val();
		}
		console.log("not found");
		return null;
	}

}
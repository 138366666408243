
import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';

import { HashRouter as Router } from 'react-router-dom';

import Routes from './Routes';

import FirebaseContext from "./Services/FirebaseContext"; 
import FirebaseHelper from "./Services/FirebaseHelper"; 


import { MuiThemeProvider} from '@material-ui/core/styles';
import theme from './Theme';

function App() { 
	
	return (
		<MuiThemeProvider theme = {theme}>
			<FirebaseContext.Provider value= {new FirebaseHelper()}>
				<Router>
					<div>
						<Routes />
					</div>
				</Router>
			</FirebaseContext.Provider>
		</MuiThemeProvider>
	);
}

export default App;


export default (theme) => ({
  root: {
    flexGrow: 1,
  },
    
  
  button: {
    margin: theme.spacing(2, 0, 2),
  },
  
  paper: {
    marginTop: theme.spacing(8),
	
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  
  gridItem: {
	  margin: theme.spacing(1, 2, 4),
	  
  },
  
  infoText: {
	color: theme.palette.text.secondary,
	margin: theme.spacing(1),

  },


  title: {
	color: theme.palette.text.primary,
	margin: theme.spacing(4),

  },
  
  divider: {
	margin: theme.spacing(4), 
  },
});
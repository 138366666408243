
//
//Helper functions
//

//Empty string function
const IsEmpty = (string) => {
	if (string.trim() === '') return true;
	else return false;
};

//is an email function
const IsEmail = (email) => {
	const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.match(emailRegEx)) 
		return true;
	else 
		return false;
}

//is an snumber functon
const IsSNumber = (sNumber) => {
	
	if (sNumber.length === 7) {		
		let num = sNumber.slice(2, sNumber.length);
		
		if (!isNaN(+num) && +num > 0 && num.length === 5) 	
			return true;
		
		else 
			return false;		
	}
	
	else 
		return false;			
}

//Makes sure the login info is good
const ValidateSignUpData = (data) => {
	
	let errors = {};
	if(data.email && data.password && data.confirmPassword){
		if (IsEmpty(data.email)) {
			errors.email = "Must not be empty";
		} else if (!IsEmail(data.email)) {
			errors.email = "Must be valid email address";
		}

		if (IsEmpty(data.password)) errors.password = "Must not be empty";
		if (data.password !== data.confirmPassword) errors.confirmPassword = "Passowrds must be the same";
	}else{
		errors.missingdata = "need to includ email password and confirmpassword"; 
	}
	
	return {
		errors,
		valid: Object.keys(errors).length === 0 ? true : false
	};

}

//Makes sure the register is good
const ValidateSignInData = (data) => {

	let errors = {};
	if(data.email && data.password){
		if (IsEmpty(data.email)) 
			errors.email = "Must not be empty";
		else if(!IsEmail(data.email))
			errors.email = "Must be valid email address";
		if (IsEmpty(data.password)) 
			errors.password = "Must not be  empty";
	}else{
		errors.missingdata = "need to includ email password and confirmpassword"; 
	}
	return {
		errors,
		valid: Object.keys(errors).length === 0 ? true : false
	};
}

//Export
export default {IsEmpty, IsSNumber, IsEmail, ValidateSignUpData, ValidateSignInData}; 
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../Components/Copyright.js"
import history from '../History';

 



const useStyles = (theme) => ({
  paper: {
	marginTop: theme.spacing(8),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
  },
  
  header: {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	
  },
  avatar: {
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
	
  },
    
  logo: {
	margin: theme.spacing(1),
    width: "50%",
  },
  
  subtitle: {
	  color: theme.palette.text.secondary,
	  margin: theme.spacing(4),

  },
  

  
}); 


class VerifyEmail extends React.Component{

	constructor(props){
		super(props);
		

	}
	

	render(){
		
	  const {classes} = this.props

	  return (
	  
		<Container component="main" maxWidth="xs">
			<CssBaseline />
		  
		  <div className={classes.paper}>
		  
			<img className = {classes.logo} src="logo.png" alt="Team Up logo" />
			
			<Avatar className={classes.avatar}>
			  <MailOutlineIcon />
			</Avatar>
			
			<Typography component="h1" variant="h5" >
				Verify your email
			</Typography>
			
			<Typography component="h2" variant="subtitle1" className = {classes.subtitle} align = 'center'>
				Please click the link in the email sent to verify your email address.
			</Typography>

			  
			<Typography variant="body2"  align="center">
				 <Link onClick={() => history.push('/Signin')} >
					Already have an account? Sign in
				 </Link>
			</Typography>

		  </div>
		  <Box mt={5}>
			  <Copyright />
		  </Box>
		</Container>
	  );
	  
	}
}

export default withStyles(useStyles)(VerifyEmail)
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../Components/Copyright.js"
import history from '../History';
import FirebaseContext from "../Services/FirebaseContext"; 
import Misc from "../Services/Misc"; 
import ResponsiveDialog from "../Components/ResponsiveDialog"; 


const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


class ChangePassword extends React.Component{

	constructor(props){
		super(props);
		
		this.password = "";		
		this.confirmPassword = ""; 
		
		this.state = {dialogOpen: false, 
					  buttonEnabled: true,
					  dialogMessage: "",
					  dialogTitle: ""}; 
		
		this.handleClick = this.handleClick.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
	}
	
	handleDone(){
		history.push('/');
	}
	
	handleClick(firebaseHelper){
		
		this.setState({buttonEnabled: false});
		
		firebaseHelper.ChangePassword(this.password)
		
		.then(()=>{
			console.log("ChangePassword DONE");
			this.setState({buttonEnabled: true});
			
			this.handleDone(); 
			
		})
		
		.catch((error)=>{
			console.log("ChangePassword ERROR ");
			
			this.setState({
				buttonEnabled: true,
				dialogOpen: true,
				dialogMessage: "Sorry there seems to be a problem:" + error.message,
				dialogTitle: "Change Password Problem!" 
			});
		}); 
	}
	
	handleDialogClose(){
		this.setState({
			dialogOpen: false
		});
		history.push('/');
	}

	render(){
		
	  const {classes} = this.props

	  return (
		<Container component="main" maxWidth="xs">
		  <CssBaseline />
		  <div className={classes.paper}>
			<Avatar className={classes.avatar}>
			  <LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
			  Update Password
			</Typography>
			<form className={classes.form} noValidate>
			  
			  <TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				name="password"
				label="Password"
				type="password"
				id="password"
				onChange={(e) => this.password = e.target.value}
			  />	

			  <TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				name="password"
				label="Retype password"
				type="password"
				id="password"
				onChange={(e) => this.confirmPassword = e.target.value}
			  />			  
			  
			  
			  <FirebaseContext.Consumer>
			  { firebaseHelper=>(
				  <Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					disabled={!this.state.buttonEnabled}
					className={classes.submit}
					onClick={()=>this.handleClick(firebaseHelper)}
				  >
					Change password
				  </Button>
			  )}
			  </FirebaseContext.Consumer>	

			  <ResponsiveDialog open={this.state.dialogOpen} 
								handleClose={this.handleDialogClose}
								message={this.state.dialogMessage}
								title={this.state.dialogTitle}/>
			  
			</form>
		  </div>
		  <Box mt={8}>
			<Copyright />
		  </Box>
		</Container>
	  );
	  
	}
}

export default withStyles(useStyles)(ChangePassword)
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import history from '../History';
import Topbar from "../Components/Topbar.js";
import HelloTitle from "../Components/HelloTitle.js";


const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

});


class Home extends React.Component{

	constructor(props){
		super(props);
				
		this.state = {buttonsEnabled: true}; 
				
	}
	

	render(){
		
	  const {classes} = this.props

		return ( 
				<div className={classes.root}> 	
				  <Topbar />
				  <div className = {classes.paper}>
					<HelloTitle />
					<Button
						type="button"
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={() => history.push('/PerformanceReviewReport')}
						disabled={!this.state.buttonsEnabled}
					>
						Create PRR
					</Button>
					</div>
				  
				</div>		 
		)
	}
}

export default withStyles(useStyles)(Home); 
